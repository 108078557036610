// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}


/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*=-=-=-=-=-=====> utility classes start here <======= -=-=-=-=-=-=-=*/

.bg {
  &--cover {
    @include background-cover;
  }

  &--fixed {
    background-attachment: fixed;
  }
}

.color {
  &--theme-color {
    color: $theme-color !important;
  }

  &--gradient-y {
    @include text-gradient($gradient-y);
  }
}

.text {

  &--3d {
    filter: drop-shadow(2px 5px 0px $title-color);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $title-color;
  }
}

.padding-top {
  padding-top: 70px;

  @include breakpoint(lg) {
    padding-top: 100px;
  }
}

.padding-bottom {
  padding-bottom: 70px;

  @include breakpoint(lg) {
    padding-bottom: 100px;
  }
}



//flex gaps
.g-20 {
  gap: 20px;
}

//margins
.mb-30 {
  margin-bottom: 30px;
}

//paddings

.px-30 {
  padding-inline: 30px;
}

.ty--40 {
  @include add-prefix(transform, translateY(-40px));
}

/*=-=-=-=-=-=====> utility classes end here <======= -=-=-=-=-=-=-=*/