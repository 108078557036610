/*===== keyframes for ripple====*/

/* ripple effect */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($theme-color, 0.3),
            0 0 0 4px rgba($theme-color, 0.3),
            0 0 0 8px rgba($theme-color, 0.3),
    }

    100% {
        box-shadow: 0 0 0 4px rgba($theme-color, 0.3),
            0 0 0 8px rgba($theme-color, 0.3),
            0 0 0 12px rgba($theme-color, 0);
    }
}


/*===== keyframes for banner img ====*/
@include keyframes(santa-ride) {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(15%);
    }
}

@include keyframes(floating) {
    0% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0px);
    }
}

@include keyframes(rotate) {

    0%,
    5% {
        transform: rotate(15deg) skew(1deg);

    }

    50% {
        transform: skew(30deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@include keyframes(zoom) {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}


@include keyframes(left-right) {
    0% {
        transform: translateX(30px);
    }

    100% {
        transform: translateX(0px);
    }
}


@include keyframes(fade) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

//glitch animation

@include keyframes(light) {

    10%,
    90% {
        opacity: .5;
    }

    20%,
    80% {
        opacity: .7;
    }

    30%,
    50%,
    70% {
        opacity: .9;
    }

    40%,
    60% {
        // transform: translate3d(4px, 0, 0);
        opacity: 1;
    }
}