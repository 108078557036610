// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

//default button style
.default-btn {
    @extend %p-rel;
    @extend %d-inline-block;
    @extend %of-hidden;
    @include font-size(18px);
    border-radius: 4px;
    text-decoration: none;
    font-family: $text-font;
    color: $title-color;
    padding: 0 1.7rem;
    line-height: 50px;
    transform-origin: right;
    transition: $transition;
    background: $gradient-x;
    box-shadow: 0 5px 0px 0px currentColor;


    @include breakpoint(md) {
        padding: 0 2.25rem;
        line-height: 56px;
    }

    span {
        position: inherit;
        z-index: 1;
        text-transform: uppercase;

        svg {
            @extend %mr-5;
        }
    }

    &__small {
        line-height: 46px;
        padding: 0 1.25rem;
    }

    &__rounded {
        @include add-prefix(border-radius, 99px);
    }

    &.move-right {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $theme-color;
            transition: transform .5s;
            transform-origin: right;
            transform: scaleX(0);
            z-index: 0;
        }

        &:hover {

            &:before {
                transform: scaleX(1);
                transform-origin: left;
                transition: transform .5s;
            }
        }
    }

    &.move-left {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $theme-color;
            transition: transform .5s;
            transform-origin: left;
            transform: scaleX(0);
            z-index: 0;
        }

        &:hover {

            &:before {
                transform: scaleX(1);
                transform-origin: right;
                transition: transform .5s;
            }
        }
    }

    &.move-bottom {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $theme-color;
            transition: transform .5s;
            transform-origin: bottom;
            transform: scaleY(0);
            z-index: 0;
        }

        &:hover {

            &:before {
                transform: scaleY(1);
                transform-origin: top;
                transition: transform .5s;
            }
        }
    }

    &.move-top {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $theme-color;
            transition: transform .5s;
            transform-origin: top;
            transform: scaleY(0);
            z-index: 0;
        }

        &:hover {

            &:before {
                transform: scaleY(1);
                transform-origin: bottom;
                transition: transform .5s;
            }
        }
    }

    &:hover {
        color: $title-color;
        transform: translateY(3px);
        background: $gradient-x-rev;
        border-color: $theme-color;
        box-shadow: none;
    }

    &-active {
        background-color: $theme-color;
        border-color: $theme-color;
    }
}