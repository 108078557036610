.accordion {
    &__item {
        @extend %of-hidden;
        background: $gradient-y;
    }

    &__button {
        @extend %w-100;
        @extend %text-left;
        @extend %flex;
        @extend %justify-between;
        @include font-size(24px);
        background-color: transparent;
        color: $title-color;
        font-family: $title-font;
        font-weight: 400;
        padding: 1.5rem 1rem;
        border: none;
        border-top: 1px solid rgba($white-color, $alpha: .20);

        .plus-icon {
            @extend %p-rel;
            @include box(14px);
            display: none;

            @include breakpoint(sm) {
                display: initial;
            }

            &:before,
            &:after {
                @extend %p-abs;
                background-color: $title-color;
            }

            &::before {
                @include add-prefix(transform, translateY(-50%));
                width: 14px;
                height: 4px;
                top: 50%;
            }

        }

        &.collapsed {
            color: $title-color;


            .plus-icon {
                &::before {
                    background-color: $title-color;
                }

                &::after {
                    @include add-prefix(transform, translateY(-50%));
                    height: 14px;
                    width: 4px;
                    top: 50%;
                    left: 5px;
                    background-color: $title-color;
                }
            }
        }
    }

    &__body {
        padding: .5rem 1.5rem 1.5rem;
        color: $title-color;
        line-height: 1.6;
        ;
    }
}

.faq {
    @extend %of-hidden;
    @extend %p-rel;

    &__thumb {
        @include breakpoint(lg) {
            position: absolute;
            bottom: 0;
        }
    }
}