.countdown {
    @extend %flex;
    gap: 30px;

    @include breakpoint(lg) {
        gap: 40px;
    }

    @include breakpoint(xl) {
        gap: 60px;
    }

    &__item {
        @extend %text-center;
    }

    &__text {
        @extend %mb-0;
    }
}