.banner {
    @extend %of-hidden;
    @extend %p-rel;
    @include background-cover;
    padding-block: 100px;

    @include breakpoint(lg) {
        padding-block: 160px;
    }

    @include breakpoint(xxl) {
        padding-block: 220px;
    }



    &__content {
        @extend %p-rel;
        z-index: 1;

        h1 {
            @extend %mb-20;
            text-transform: capitalize;

        }

        h1,
        p {
            color: $text-color;
        }

        p {
            max-inline-size: 35ch;
            margin-inline: auto;
            @extend %mb-45;
            @include font-size(30px);
            line-height: 1.65;
        }
    }

    &__apes {
        &-item {
            @extend %p-abs;
            bottom: 0;
            max-inline-size: 20%;

            &:nth-child(1) {
                left: 25%;
            }

            &:nth-child(2) {
                left: 5%;
            }

            &:nth-child(3) {
                right: 25%;
            }

            &:nth-child(4) {
                right: 5%;
            }

            @include breakpoint(sm) {

                &:nth-child(1) {
                    left: 15%;
                }

                &:nth-child(2) {
                    left: 4%;
                }

                &:nth-child(3) {
                    right: 10%;
                }

                &:nth-child(4) {
                    right: 1%;
                }
            }

            @include breakpoint(lg) {
                max-inline-size: 20%;

                &:nth-child(1) {
                    left: 18%;
                }

                &:nth-child(2) {
                    left: 3%;
                }

                &:nth-child(3) {
                    right: 15%;
                }

                &:nth-child(4) {
                    right: 1%;
                }
            }

            @include breakpoint(xxl) {
                max-inline-size: 100%;

                &:nth-child(1) {
                    left: 20%;
                }

                &:nth-child(2) {
                    left: 5%;
                }

                &:nth-child(3) {
                    right: 20%;
                }

                &:nth-child(4) {
                    right: 5%;
                }
            }
        }
    }
}

.bg-attach--fixed {
    @include breakpoint(lg) {
        background-attachment: fixed;
    }

}